@import "@core/scss/core.scss";
@import "./assets/scss/styles";
@import "@core/scss/angular/libs/select.component.scss";
@import "./assets/scss/animate.min.css";

@font-face {
    font-family: "icomoon";
    src: url("assets/fonts/icomon/icomoon.eot?anr1ma");
    src: url("assets/fonts/icomon/icomoon.eot?anr1ma#iefix") format("embedded-opentype"), url("assets/fonts/icomon/icomoon.ttf?anr1ma") format("truetype"), url("assets/fonts/icomon/icomoon.woff?anr1ma") format("woff"), url("assets/fonts/icomon/icomoon.svg?anr1ma#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

html {
    body {
        background-color: rgb(255 255 255) !important;
        color: rgba(21, 40, 88, 1) !important;
        max-width: 100vw !important;
    }
}

.flux-container {
    background: rgb(240 243 255 / 50%) !important;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "icomoon",sans-serif !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-Accounting:before {
    content: "\e900";
}

.icon-Administration:before {
    content: "\e901";
}

.icon-car:before {
    content: "\e902";
}

.icon-chat:before {
    content: "\e903";
}

.icon-ContractorManagement:before {
    content: "\e904";
}

.icon-CustomerSuccess:before {
    content: "\e905";
}

.icon-Dashboard:before {
    content: "\e906";
}

.icon-notification:before {
    content: "\e907";
}

.icon-SafetyInsurance:before {
    content: "\e908";
    color: #1b3065;
}

.icon-Search:before {
    color: #fff;
}

.fa-Dashboard::before {
    font-family: "icomoon",sans-serif !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e906";
}

.fa-CustomerCare::before {
    font-family: "icomoon",sans-serif !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e902";
}

.fa-Customer-Success::before {
    font-family: "icomoon",sans-serif !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e905";
}

.fa-Partner-Care::before {
    font-family: "icomoon",sans-serif !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e904";
}

.fa-Safety-Compliance::before {
    font-family: "icomoon",sans-serif !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e908";
}

.fa-Accounting::before {
    font-family: "icomoon",sans-serif !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e900";
}

.fa-Administration::before {
    font-family: "icomoon",sans-serif !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e901";
}

.header-title {
    font-size: 1.5rem;
    font-weight: 400;
    color: #fff;
}

.flux-mcaret {
    border-color: #fff !important;
}

.title-left-header {
    display: inline-block;
    font-size: 19.5px;
    color: #fff !important;
    margin-left: 2.5rem;
    padding: 0 20px;
    position: relative;
    top: -5px !important;
    padding-right: 34px;
}

.flux-header-navbar .flux-left-header .left-header-nav ul li:hover ul li a {
    color: #52657c !important;
}

.flux-header-navbar
.flux-left-header
.left-header-nav
ul
li:hover
ul
li:hover
a {
    color: #1877f2 !important;
}

.flux-header-navbar {
    .flux-left-header {
        .left-header-nav {
            .second-level-menu {
                min-width: 250px;
                border: none;
                margin-top: 0;
                min-height: 52px;
                border-radius: 0.358rem;
                -webkit-animation-duration: 0.3s;
                animation-duration: 0.3s;
                -webkit-animation-fill-mode: both;
                animation-fill-mode: both;
                -webkit-animation-name: slideIn;
                animation-name: slideIn;
                box-shadow: 0 10px 15px #22292f1a;
                padding: 0.5rem 8px !important;

                li {
                    height: auto !important;
                    padding: 1px 0px !important;

                    a {
                        padding: 0.65rem 1.28rem !important;
                        clear: both;
                        font-size: 1rem;
                        padding-top: 0.68rem;
                        padding-bottom: 0.68rem;
                        display: flex;
                        align-items: center;
                        line-height: 1.5;
                    }

                    a.active {
                        border-radius: 7px;
                        color: #1877f2 !important;
                        background: rgba(228, 233, 240, 0.7) !important;
                    }
                }

                li:hover {
                    a {
                        border-radius: 7px;
                        color: #1877f2 !important;
                        background: rgba(228, 233, 240, 0.7) !important;
                    }

                    ul.third-level-menu {
                        min-width: 250px !important;
                        padding: 7px 7px !important;

                        li {
                            a {
                                color: #52657c !important;
                                background: transparent !important;
                            }
                        }

                        li:hover {
                            a {
                                border-radius: 7px;
                                color: #1877f2 !important;
                                background: rgba(228, 233, 240, 0.7) !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .flux-right-header {
        ul {
            li {
                a {
                    span {
                        color: #fff !important;
                    }
                }
            }
        }
    }
}

.menu-list {
    .menu-item {
        ul {
            padding: 7px 7px !important;
            box-shadow: 0 10px 15px #22292f1a;

            li {
                a {
                    color: #152858 !important;
                    padding: 0.35rem 0.35rem !important;
                    margin: 1px 0;
                    line-height: 25px;
                }
            }

            li:hover {
                a {
                    color: #1877f2 !important;
                    background: rgba(228, 233, 240, 0.7) !important;
                    border-radius: 7px;
                }
            }

            li.active {
                a {
                    color: #1877f2 !important;
                    background: rgba(228, 233, 240, 0.7) !important;
                    border-radius: 7px;
                }
            }
        }
    }

    li {
        a {
            span.flux-mcaret {
                margin-top: -2px !important;
            }
        }

        ul {
            li {
                a {
                    padding: 0 7px;

                    span.flux-mcaret {
                        margin-top: 5px !important;
                        margin-right: 4px;
                    }
                }
            }
        }
    }
}

#actionBar {
    border-bottom: solid #d6d9e4 1px !important;
    padding: 22px 0;
}

#SubactionBar {
    height: 42px;
    border-bottom: solid #d6d9e4 1px !important;
    padding: 5px 0;
    margin-bottom: 23px !important;
}

// #SubactionBardetail{
//   height: 42px;
//   border-bottom: solid #d6d9e4 1px !important;
//   padding: 5px 0;
// }

.btn-info {
    border-color: #1877f2 !important;
    background-color: #1877f2 !important;
    color: #fff !important;
    box-shadow: 0 5px 15px -8px #1877f2;
}


.btn-info:hover:not(.disabled):not(:disabled) {
    box-shadow: 0 5px 15px -8px #1877f2;
}

.btn-info:hover {
    color: #fff !important;
    background-color: #0c65d8 !important;
    border-color: #0b5fcc !important;
}

.btn-info:focus {
    background-color: #0c65d8 !important;
    border-color: #0b5fcc !important;
}

.btn-close:hover:not(.disabled):not(:disabled) {
    box-shadow: 0 5px 15px -8px #c3c4c5;
}

.btn-close:hover {
    background-color: #ebebeb !important;
    border-color: #acb7bf !important;
}

.btn-close:focus {
    background-color: #ebebeb !important;
    border-color: #acb7bf !important;
}

.sub-title {
    font-size: 1.5rem;
    color: rgba(21, 40, 88, 1);
    padding: 0px 0 10px 0px;
    font-weight: 600;
}

.breadcrumb {
    padding-left: 0px !important;
}

table.dataTable.row-border tbody th,
table.dataTable.row-border tbody td,
table.dataTable.display tbody th,
table.dataTable.display tbody td {
    border-top: none !important;
}

.adr-table {
    thead {
        tr {
            th {
                background: rgba(228, 233, 240, 0.7);
                color: rgba(82, 101, 124, 1);
                border-bottom: none !important;
                font-size: 13px;
            }

            th:first-child {
                padding: 0.72rem 1rem !important;
            }
        }
    }

    tbody {
        tr {
            td:first-child {
                padding: 0.72rem 1rem !important;
            }

            td {
                border-top: 0px !important;
                border-bottom: solid 0.5px rgba(214, 217, 228, 0.7) !important;

                .card-control-padding:last-child {
                    padding-bottom: 0px !important;
                }

                .custom-control {
                    top: 5px;
                }
            }
        }
    }
}

.btn.btn-link {
    color: rgba(24, 119, 242, 1) !important;
    font-weight: 400;
}

.btn.btn-link:hover {
    font-weight: 500;
}

.badge-success {
    background: rgba(40, 199, 111, 0.15);
    color: rgba(40, 199, 111, 1) !important;
    font-weight: 400;
}

.badge-danger {
    background-color: rgba(234, 84, 85, 0.15);
    color: rgba(234, 84, 85, 1) !important;
    font-weight: 400;
}

.badge-secondary {
    color: rgba(82, 101, 124, 0.7) !important;
    background-color: rgba(241, 241, 242, 1);
    font-weight: 400;
}

.badge-warning {
    color: rgba(255, 159, 67, 1) !important;
    background-color: rgba(255, 159, 67, 0.15);
    font-weight: 400;
}

.badge-primary {
    color: rgba(24, 119, 242, 1) !important;
    background-color: rgba(24, 119, 242, 0.15);
    font-weight: 400;
}

.filters-section {
    background: rgba(240, 243, 255, 0.5);
    padding-top: 1rem;
    box-shadow: 0 5px 15px 0px rgb(34 41 47 / 10%);
    border-radius: 7px;
    display: flex;
    flex-wrap: wrap;
}

.btn-sm,
.btn-group-sm > .btn {
    font-size: 13px !important;
    font-weight: 400 !important;
    line-height: 14px;
    padding: 0.486rem 1rem !important;
    border-radius: 0.358rem;
    margin: 2px;
}

.form-control {
    display: block;
    width: 100%;
    height: 2.714rem;
    padding: 0.438rem;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.45;
    color: #52657c;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control:focus,
.form-control:focus-visible {
    color: #52657c;
    background-color: #fff;
    border-color: #1877f2 !important;
    outline: 0;
    box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
}

.child-submenu {
    position: relative !important;
    left: 0 !important;
    max-width: 100% !important;
    padding-left: 25px !important;
    box-shadow: none !important;
    -webkit-transition: height 0.6s ease;
    min-width: 234px !important;

    li {
        a {
            &::before {
                content: "\f111";
                display: inline-block;
                font: normal  14px/1 ;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                color: rgb(82 101 124 / 30%);
                padding-right: 15px;
                font-size: 11px;
            }
        }

        a:hover {
            &::before {
                color: #1877f2 !important;
            }
        }
    }
}

.subMenu:hover {
    ul.child-submenu {
        li {
            a {
                font-weight: 400;
                color: #52657c !important;
                background: transparent !important;
                border-radius: 7px;
            }

            a:hover {
                color: #1877f2 !important;
                background: rgba(228, 233, 240, 0.7) !important;
                border-radius: 7px;
            }
        }
    }

}

ul.nav-tabs.justify-content-end.nav {
    border-bottom: solid 1px rgba(214, 217, 228, 1);

    .nav-item {
        border-left: solid 1px rgba(214, 217, 228, 1);

        a {
            span {
                i {
                    margin: auto;
                }

                display: inline-block;
                width: 22px;
                height: 22.81px;
                left: 1044px;
                top: 230.44px;
                background: #e4e9f0;
                border-radius: 6px;
                text-align: center;
                padding-top: 2px;
                color: #1877f2;
                margin: 0 7px 0 0;
            }
        }
    }
}


.form-control-adr {
    color: #152858 !important;
    background: #f1f1f2;
    border: none;
    font-size: 13px !important;
    height: 2.142rem;
    padding: 0.188rem 0.857rem;
    line-height: 1;
    border-radius: 0.357rem;
    font-weight: 500;
}

#page-account-settings .password {
    color: rgba(82, 101, 124, 0.7) !important;
    background: #f1f1f2;
    border: none;
    font-size: 13px !important;
    border-right-style: none !important;
}

#page-account-settings .password:focus {
    background-color: #f1f1f2;
    border: solid 1px #1877f2;

}

.form-control-adr:focus {
    background-color: #f1f1f2;
    border: solid 1px #1877f2;
}

.inbut-container {
    display: table-row;

    .inbut-label {
        label {
            color: #52657c;
            font-weight: 400;
            padding-top: 5px;
            font-size: 13px !important;
        }
    }
}


.tag-backgraound {
    background: rgba(236, 240, 244, 0.7) !important;
    padding: 5px 3px;
    padding-right: 28px;
    border-radius: 5px;

    .btn-sm-adr {
        position: absolute !important;
        top: 6px !important;
        right: 17px !important;
    }
}

.adr-tag {
    display: inline-block;
    white-space: nowrap;
    color: #1877f2;
    border: 1px solid #d6d9e4;
    border-radius: 5px;
    padding: 2px 10px;
    margin: 1px 2px;
    background: rgba(236, 240, 244, 0.7);

    a.btn.btn-link {
        color: rgb(82 101 124 / 70%) !important;
        position: relative;
        bottom: 1px;
        right: -3px;
    }

    a.btn.btn-link:hover {
        color: #1877f2 !important;
        position: relative;
        bottom: 1px;
        right: -3px;
    }
}

.page-sub-title {
    color: #152858 !important;
    font-size: 15px !important;
    font-weight: 600;
    position: relative;
    top: 2px;
    margin-bottom: 10px;

    .adr-tag {
        font-size: 13px !important;
        font-weight: 400 !important;
        margin-left: 0px !important;
    }
}

.dropdown-toggle::after {
    display: none !important;
}

.btn.btn-sm-adr {
    padding: 2px 4px 1px 4px !important;
    float: right;
    position: relative;
    top: -2px;
}

.card-adr {
    border: 1px solid #d9d9d9;
    border-radius: 5px;

    .card-body {
        padding: 8px;

        .card-adr-header {
            margin-bottom: 5px;
        }
    }
}

label {
    font-size: 13px !important;
}

.input-group-adr {
    .input-group-append {
        background: #f1f1f2;
        padding: 2px 5px;
        color: rgba(21, 40, 88, 0.5);
        border-top-right-radius: 4.641px;
        border-bottom-right-radius: 4.641px;
        border-left-style: none !important;

        i {
            background: #fff !important;
            padding: 2px 4px;
            border-radius: 5px;
            margin: 2px 0;
            width: 20px;
            text-align: center;
        }

        i.charter {
            background: #fff !important;
            padding: 0px 4px;
            border-radius: 5px;
            margin: 2px 0;
            font-weight: bold;
            font-style: normal;
            width: 20px;
            text-align: center;
        }
    }
}

.badge-minus {
    width: 14px;
    height: 14px;
    border-radius: 4px;
    text-align: center;
    padding: 3.5px 0px;

    i {
        height: 10px;
        width: 10px;
        font-size: 9px;
        font-weight: 300;
        padding-bottom: 0px;
        position: relative;
    }
}

.card-control-padding {
    padding-bottom: 3px !important;
}

p.page-sub-title {
    font-size: 13px !important;
}

.badge-secondary.badge-minus {
    background: #d9d9d9;

    i {
        color: #fff !important;
    }
}

.badge-secondary.badge-info {
    background: #f1f1f2;
    padding: 2px;
    border-radius: 50%;
    margin-right: 8px;

    i {
        color: #1877f2 !important;
    }
}

.table-control-h {
    height: 30px;
    display: flex;
    align-items: center;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.table.adr-table {
    tbody {
        tr {
            td {
                vertical-align: top;
            }

            td:last-child {
                padding-right: 0px !important;
            }
        }
    }
}

.ql-toolbar.ql-snow {
    background: rgba(236, 240, 244, 0.7);
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-top: 1px solid #d6d9e4 !important;
    margin-top: 7px;
    border: 1px solid #d6d9e4;
    border-radius: 5px;
}

.form-check-label {
    margin-bottom: 0;
    padding-right: 10px;
}

h3 {
    font-size: 15px !important;
}

.hint {
    color: rgba(82, 101, 124, 0.7) !important;
    font-size: 10px;
    text-transform: uppercase;
}

.list-group-adr {
    height: 232px;
    overflow-y: scroll;

    li {
        border: 0;
        border-bottom: solid 0.5px rgba(214, 217, 228, 0.7) !important;
    }

    li:last-child {
        border: 0;
        border-bottom: none;
    }
}

.list-group-adr:hover {
    overflow-y: scroll;
}

.list-group-adr::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #f5f7fd;
    border-radius: 10px;
    background-color: #f5f5f5;
}

.list-group-adr::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
}

.list-group-adr::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #f5f7fd;
    background-color: #52657c;
}


.pl-20px {
    padding-left: 20px !important;
}

.btn-sm-adr i {
    font-size: 12px;
    font-weight: 300;
}

.inbut-control {
    display: table-cell;
    width: 100%;
    .ng-select .ng-select-container {
        color: #52657c !important;
        min-height: 28px !important;
        height: 28px !important;
        background: #f1f1f2;
        border: none;
    }
}

.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
    color: rgba(255, 255, 255, 0.85);
    background-color: #1877f2 !important;
}

[navbar-type="collapsed"] .overlay {
    display: none;
}

[navbar-type="expanded"] .overlay {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: rgba(82, 101, 124, 0.4);
    top: 0px;
    z-index: 928;
    right: 0;
    -webkit-transition: height 0.1s ease;
}


.flux-wrapper {
    min-height: 100vh !important;
    .flux-navbar {
        .menu-list {
            li {
                ul {
                    li {
                        a {
                            .flux-mcaret {
                                border-color: #52657c !important;
                                margin-top: 5px;
                                display: inline-block !important;
                            }
                        }
                    }

                    li:hover {
                        a {
                            .flux-mcaret {
                                border-color: #1877f2 !important;
                            }
                        }

                        ul {
                            li {
                                a {
                                    background: transparent !important;
                                    color: #152858 !important;
                                }
                            }

                            li:hover {
                                a {
                                    color: #1877f2 !important;
                                    background: rgba(228, 233, 240, 0.7) !important;
                                    border-radius: 7px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.menu-item.hasMenu.active {
    a {
        .flux-mcaret {
            border-color: #1877f2 !important;
        }
    }
}

[navbar-type="expanded"] {
    .flux-header-wrapper {
        .flux-brand {
            padding-left: 9px !important;

            .sidebarToggle {
                span {
                    background-color: #fff !important;
                    width: 34px;
                    height: 34px;
                    border-radius: 7px;
                    padding: 8px 0px 11px 0px;

                    svg {
                        width: 34px;
                        height: 34px;
                        padding: 6px;

                        rect {
                            fill: rgba(24, 119, 242, 1);
                        }
                    }
                }
            }
        }
    }
}

[navbar-type="collapsed"] {
    .flux-header-wrapper {
        .flux-brand {
            padding-left: 9px !important;

            .sidebarToggle:hover {
                span {
                    background-color: #fff !important;
                    width: 34px;
                    height: 34px;
                    border-radius: 7px;
                    padding: 8px 0px 11px 0px;

                    svg {
                        rect {
                            fill: rgba(24, 119, 242, 1);
                        }
                    }
                }
            }

            .sidebarToggle {
                svg {
                    background-color: transparent !important;
                    width: 34px;
                    height: 34px;
                    padding: 6px;

                    rect {
                        fill: rgb(255, 255, 255);
                    }
                }
            }
        }
    }

    .flux-wrapper {
        .flux-navbar {
            .menu-list {
                padding-top: 7px !important;

                li {
                    a {
                        .flux-mcaret {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}


.horizantal-nav-tabs {
    padding: 0rem 1.4rem;
    margin-top: 1.4rem;

    &::before {
        content: "";
        left: 0;
        width: 100%;
        height: 1px;
        border-bottom: solid 1px rgba(214, 217, 228, 1);
        bottom: 0;
        position: absolute;
    }

    li {
        border-left: solid 1px rgba(214, 217, 228, 1);
        border-right: 0;
        border-bottom: solid 1px rgba(214, 217, 228, 1);
        border-top: solid 1px rgba(214, 217, 228, 1);
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;

        a.nav-link.active {
            position: relative !important;
            color: #52657c !important;
            font-size: 14px !important;
            background: #ffff !important;
            height: calc(100% + 1px) !important;
        }

        a {
            background: rgba(214, 217, 228, 0.2);
            color: rgba(82, 101, 124, 1) !important;
        }
    }

    li:last-child {
        border-right: solid 1px rgba(214, 217, 228, 1);
    }
}

.modal-img {
    width: 90px;
    margin: auto 0;
}

.ng-select.ng-select-focused.ng-select-bottom {
    border-bottom: 1px solid #d6d9e4 !important;
}

.ng-select.ng-select-focused.ng-select-top .ng-select-container {
    border-bottom: 1px solid #1877f2 !important;
    border-left: solid 1px #1877f2 !important;
    border-right: solid 1px #1877f2 !important;
    border-top: solid 1px #d6d9e4 !important;
}

.ng-select.ng-select-focused.ng-select-top .ng-dropdown-panel {
    border-top: solid 1px #1877f2 !important;
}

.ng-select.ng-select-focused {
    border-radius: 4px;
}

.ng-select.ng-select-focused:not(.ng-select-opened) .ng-select-container {
    border-bottom: 1px solid #1877f2 !important;
}

.ng-select.ng-select-focused .ng-select-container {
    border-color: #1877f2 !important;
    z-index: 2000 !important;
    box-shadow: none !important;
    color: #52657c !important;
    border-bottom: 1px solid #d6d9e4 !important;
    border-left: solid 1px #1877f2 !important;
    border-right: solid 1px #1877f2 !important;
    border-top: solid 1px #1877f2 !important;
}

.k-pager {
    border-style: none;
}

.vertical-nav-tabs {
    padding: 0px 1rem;

    li:last-child {
        .nav-link {
            border-bottom: solid 1px #d6d9e4;
        }
    }

    .nav.nav-tabs.nav-left {
        padding: 30px 0;
        margin-bottom: 0;

        &::before {
            content: "";
            height: 100% !important;
            border-left: solid 1px rgba(214, 217, 228, 1);
            position: absolute;
            right: 0;
            top: 0;
        }

        .nav-link {
            border-left: solid 1px #d6d9e4;
            border-top: solid 1px #d6d9e4;
            background: rgba(214, 217, 228, 0.2);
            min-width: 175px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            padding-top: 8.6px;
            padding-bottom: 8.6px;
        }

        .nav-link.active {
            background: #fff !important;
            font-size: 13px !important;
        }

        .nav-link.active:after {
            display: none;
        }
    }
}

.app-content.content {
    padding: 3.55rem 0 0 0;
    transition: none !important;
}

.user-nav {
    flex-direction: column;
    align-items: flex-end;
    float: left;
    margin-right: 0.8rem;
}

#navbarUserDropdown {
    background: transparent !important;
    padding: 0px !important;
    color: #fff;
}

.flux-right-header {
    ul {
        li {
            a {
                padding: 0px !important;

                svg {
                    width: 16px;
                    height: 16px;
                    color: #fff;
                }
            }
        }
    }
}


.badge.badge-up {
    background: #1877f2;
    border-radius: 5px;
    position: absolute;
    top: -8px;
    right: -8px;
    min-width: 1rem;
    min-height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.786rem;
    line-height: 0.786;
    padding-bottom: 4px;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.dropdown-chat,
.dropdown-notification {
    padding: 1px 11px !important;
    position: relative !important;
    top: 8px;
    right: 20px;
}

.dropdown-user-control {
    a.dropdown-item {
        display: block;
        width: 100%;
        padding: 0.65rem 1.28rem !important;
        clear: both;
        font-weight: 400;
        color: #52657c !important;
        text-align: inherit;
        white-space: nowrap;
        background-color: transparent;
        border: 0;

        svg {
            width: 16px !important;
            height: 16px !important;
            color: #52657c !important;
        }
    }

    a.dropdown-item.disabled,
    .dropdown-item:disabled {
        color: #b9b9c3 !important;
        pointer-events: none !important;
        background-color: transparent !important;

        svg {
            width: 16px !important;
            height: 16px !important;
            color: #b9b9c3 !important;
            pointer-events: none !important;
            background-color: transparent !important;
        }
    }

    a.dropdown-item:hover {
        color: #1877f2 !important;
        text-decoration: none;
        background-color: rgba(228, 233, 240, 0.7) !important;

        svg {
            color: #1877f2 !important;
        }
    }

    a.dropdown-item:focus {
        color: #1877f2 !important;
        text-decoration: none;
        background-color: rgba(228, 233, 240, 0.7) !important;

        svg {
            color: #1877f2 !important;
        }
    }
}

#notification-tab .nav-item a {
    cursor: pointer !important;
    font-size: 13px !important;
    font-weight: bold !important;
    color: #615d7c !important;
    padding: 4px 0;
}

#notification-tab .nav-item a:hover {
    font-weight: bold !important;
    bottom: 0px !important;
}

#notification-tab .nav-item a.active {
    border-bottom: 2px solid #1877f2;
    color: #1877f2 !important;
    top: 2px;
}

.notification-icon-menu .dropdown-menu {
    min-width: 450px !important;
    max-height: 80vh;
    /*overflow-y: scroll;*/
}


.right-menu li a {
    padding: 8px 0;
}

.avatar.bg-light-danger {
    background: rgb(250 150 142 / 50%) !important;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
    padding-top: 3px;
    color: #b43d34 !important;

    svg {
        color: #b43d34 !important;
        height: 14px !important;
        width: 14px !important;
        top: -4px;
        position: relative;
    }
}

.avatar.bg-light-success {
    color: #368147;
    background: rgb(127 220 148 / 50%) !important;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
    padding-top: 3px;

    svg {
        color: #368147 !important;
        height: 14px !important;
        width: 14px !important;
        top: -4px;
        position: relative;
    }
}

.avatar.bg-light-warning {
    background: rgb(248 224 139 / 50%) !important;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
    padding-top: 3px;
    color: #927f39 !important;

    svg {
        color: #927f39 !important;
        height: 14px !important;
        width: 14px !important;
        top: -5px;
        position: relative;
    }
}

#MarkAllAsRead {
    padding: 0px;
    margin: 0px;
    color: #615d7c !important;
    cursor: pointer;
    font-size: 13px !important;
}



.notification-item {
    margin: 0px !important;
}

#notification-tab {
    border-bottom: solid 2px #f2f4f8;
    margin: 0 13px;
}



#notification-tab .nav-item:first-child a {
    margin-left: 0px !important;
}

.markread-checkbox {
    overflow: hidden;
    position: absolute;
    right: 23px;
    margin-top: -17px;
}

.markread-checkbox__input {
    position: absolute;
    top: -100px;
    left: -100px;
}

.markread-checkbox__inner {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: transparent no-repeat center;
    right: 0px !important;
}

.markread-checkbox__inner:hover {
    border: solid 1px #1877f2 !important;
}

.markread-checkbox__input:checked + .markread-checkbox__inner {
    border-color: transparent;
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 31.96 31.95'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%231877f2%3B%7D%3C/style%3E%3C/defs%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='Capa_1' data-name='Capa 1'%3E%3Cpath class='cls-1' d='M27.25 4.65a16 16 0 1 0 .05 22.6A16 16 0 0 0 27.25 4.65Z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-size: 7px 9px;
    right: 0px !important;
}

.right-menu li a.notification-item:hover {
    padding: 8px 8px;
    background: rgba(228, 233, 240, 0.5);
    border-radius: 7px !important;
}

.right-menu li a.notification-item {
    padding: 8px 8px;
}

.notification-scroll {
    overflow-y: scroll;
    height: 60vh;
    scrollbar-width: auto;
    scrollbar-color: #8c8c8c #ffffff;
}


/* Chrome, Edge, and Safari */
.notification-scroll::-webkit-scrollbar {
    width: 12px;
}

.notification-scroll::-webkit-scrollbar-track {
    background: #ffffff;
}

.notification-scroll::-webkit-scrollbar-thumb {
    background-color: #8c8c8c;
    border-radius: 10px;
    border: 3px solid #ffffff;
}

.stiky-header {
    padding: 7px 0;
    height: 50px !important;
}

#Main-title .dropdown-header {
    width: 100% !important;
}



.cui-theme-light.cui-menu-left-toggled
.cui-layout
.cui-menu-left-inner
.cui-menu-left-scroll
.cui-menu-left-list
li.active:hover
a {
    background: transparent !important;
    margin: 0 8px !important;
}

.cui-theme-light.cui-menu-left-toggled
.cui-layout
.cui-menu-left-inner
.cui-menu-left-scroll
.cui-menu-left-list
li:hover
a {
    margin: 0 8px !important;
}

.notification-title {
    font-size: 1.3rem;
    color: #0e0b20;
    font-weight: 600;
}


#navbarNotificationDropdown {
    min-width: 450px;
    left: -285px !important;
    top: 10px !important;
}

.notification-menu {
    li.notification-menu-list {
        width: 100%;
    }
}

@media (max-width: 768px) {
    html #Page-content {
        width: 100% !important;
    }
}


/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .container,
    .container-fluid,
    .container-xxl,
    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xs {
        width: 100%;
        padding-right: 3.65rem !important;
        padding-left: 3.65rem !important;
        margin-right: auto;
        margin-left: auto;
    }

    .modal-xl {
        max-width: 1200px;
        margin: 0 auto !important;
    }
}

.cdk-global-scrollblock {
    overflow-y: hidden !important;
}



ul {
    li {
        a.notification-item {
            padding: 8px 8px !important;
            font-size: 13px !important;
            color: #52657c !important;

            .media {
                .media-left {
                    margin: 3px 10px 0 0 !important;
                }
            }
        }
    }
}

.notification-item:hover {
    padding: 8px 8px;
    background: rgba(228, 233, 240, 0.5) !important;
    border-radius: 7px !important;
    color: #52657c !important;
}

.notification-menu .media-heading {
    margin-bottom: 0px !important;
}

small.notification-text {
    color: #c0bdd0 !important;
}

#MarkAllAsRead:hover {
    font-weight: 400 !important;
    text-decoration: underline;
}

.section-title {
    color: #615d7c !important;
    font-size: 13px !important;
    padding-bottom: 5px !important;
}

.flux-micon .fa-Dashboard {
    font-size: 13px !important;
}

.flux-micon .fa-CustomerCare {
    font-size: 13px !important;
}

.flux-micon .fa-Customer-Success {
    font-size: 12px !important;
}

.flux-micon .fa-Partner-Care {
    font-size: 12px !important;
}

.flux-micon .fa-Safety-Compliance {
    font-size: 14px !important;
}

.flux-micon .fa-Accounting {
    font-size: 12px !important;
}

.flux-micon .fa-Administration {
    font-size: 12px !important;
}


.inbut-label {
    color: #52657c;
    font-weight: 400;
    line-height: 27.8px;
    font-size: 13px !important;
    padding-right: 20px;
    white-space: nowrap;
}


.no-nowrap {
    white-space: nowrap;
}

.input-group-number {
    max-width: 130px;
    width: 130px;

    .ng-select {
        .ng-select-container {
            .ng-clear-wrapper {
                display: none;
            }

            .ng-arrow-wrapper {
                display: none;
            }

            .ng-value-container {
                .ng-value {
                    .ng-value-icon {
                        padding: 1px 2px;
                    }

                    .ng-value-label {
                        padding: 1px 6px 1px 1px;
                    }

                    background-color: #ffffff !important;
                    color: rgba(21, 40, 88, 0.5);
                    font-weight: 700;
                }
            }
        }
    }
}

.inbut-control-normal-display {
    display: inline-block !important;
}

.table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
.table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
    background-color: rgba(236, 240, 244, 0.7) !important;
}


.modal-dialog {
    margin-top: 5rem !important;
}

.vertical-nav-tabs .nav-tabs .nav-link.active::before {
    content: "";
    position: absolute;
    left: 0;
    width: 4px;
    height: 102%;
    background: #1877f2 !important;
    transition: transform 0.3s;
    transform: translate3d(0, 150%, 0);
    top: -55px;
    font-size: 13px !important;
}

.vertical-nav-tabs .nav-tabs .nav-link::before {
    content: "";
    position: absolute;
    left: 0;
    width: 4px;
    height: 100%;
    background: #f7f7fa !important;
    transition: transform 0.3s;
    transform: translate3d(0, 150%, 0);
    top: -54px;
}

.ng-option {
    .ng-option-label {
        .shortcut {
            display: none;
        }
    }
}

.ng-value-container {
    height: 30px;

    .ng-value {
        height: 18px;

        .ng-value-label {
            .full {
                display: none;
            }
        }
    }
}
.adr-table-tag {
    color: rgba(21, 40, 88, 1) !important;
    border: 1px solid #d6d9e4;
    border-radius: 5px;
    background: rgba(236, 240, 244, 0.7);
    padding: 3px;
    font-weight: 500;
    margin: 0px 2px;
}

.ngx-datatable .datatable-header .datatable-header-cell .sort-btn {
    position: absolute;
    left: 7px;
}

.ngx-datatable.bootstrap.core-bootstrap
.datatable-header
.datatable-header-cell-template-wrap {
    font-weight: 500;
    line-height: calc(51px - 0.75rem);
    padding-right: 0px;
}

.sortable {
    .datatable-header-cell-template-wrap {
        .datatable-header-cell-wrapper {
            padding-left: 12px;
        }
    }
}

.datatable-header-cell-wrapper {
    color: #52657c !important;
    font-size: 13px !important;
    font-weight: 500;
}

.ngx-datatable .datatable-body .datatable-scroll {
    color: #152858;
}

.ngx-datatable.bootstrap.custom-bootstrap .datatable-header {
    background-color: rgb(236 240 244) !important;
    text-transform: uppercase;
}

.form-control-sm {
    height: 2.142rem;
    padding: 0.188rem 0.857rem;
    font-size: 0.857rem;
    line-height: 1;
    border-radius: 0.357rem;
}

select.form-control:not([multiple="multiple"]) {
    padding-right: 2.5rem;
}

.btn-light.disabled,
.btn-light:disabled {
    color: #152858;
    background-color: rgba(228, 233, 240, 0.8);
    border-color: rgba(228, 233, 240, 0.8);
}

.btn-light {
    color: #152858;
    background-color: rgba(228, 233, 240, 0.8);
    border-color: rgba(228, 233, 240, 0.8);
}

.btn-Load-More {
    display: inline-block;
    padding-right: 10px;
}

.details-btn-Load-More {
    position: relative !important;
    bottom: 39px;
    margin-right: 40px;
}


#Loader {
    width: 99vw;
    height: 100vh;
    position: fixed;
    top: 0;
    background: rgba(82, 101, 124, 0.2);
    z-index: 1051;
}

.page-count {
    display: inline-block;
    padding-left: 10px;
}
span.flux-mtext {
    font-weight: 400;
}


.datatable-row-center:hover .datatable-body-cell {
    background-color: #f0f3ff;
}

@media (max-width: 950px) {
    .left-header-nav {
        display: none !important;
    }

    .title-left-header {
        top: 10px !important;
    }
}

@media (max-width: 768px) {
    .flux-header[nav-layout="vertical"][navbar-type="offcanvas"][collapsed-left-header="true"]
    .flux-brand {
        width: 50px;
        left: 0px !important;
        top: auto !important;
    }

    [ng-reflect-toggle-status="true"] .app-content.content {
        margin-left: 0px !important;
    }

    .vertical-layout.vertical-menu-modern .content,
    .vertical-layout.vertical-menu-modern .footer {
        padding: 45px 0px !important;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    /* For landscape layouts only */
    .flux-right-header {
        margin-right: 50px !important;
    }
}

.btn-page-action {
    padding: 7px !important;
    width: 29px;
    height: 29px;
}

textarea.form-control {
    line-height: 2.4 !important;
}

.ng-select-opened .ng-select-container .ng-arrow-wrapper span {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAASCAYAAABFGc6jAAAACXBIWXMAAA9kAAAPZAGFhcKfAAABE0lEQVQ4jaXVvZHCMBAF4Ae5hgtRRgfnEtzB0QG4AshQCcqgg4MOrgTowJRwkdIDFWBmYT3j859W4kUOpPdZGsmeVFWFOtr4EkDprFrjjWjjtwCoI3dW/VHTtIEcAXwCWPFzUnjunrvO2vgP6nmuSBt/ALBpFV+bbyQJI6vW0IuzKp/Md3da4vdAjxgbQOqcaOsOI/P/LT8RoWQE5QBuqZgA+SVj6qwqUzEBQp1L2vrnqYvEFhFIzt1o36OMygDMAgU05kuKdKAIbCwdBM0LW4cHvPNlWLeRXggv7AdAkYAUPLeTXoixYyRW8JzeDEKR2CiCECTEgogICmAnCSKGBjBCxKezc49C0cZTeeasop+bLAAeJCySL9LhFOcAAAAASUVORK5CYII=);
    background-size: 8px 5px, 9px 7px;
    background-repeat: no-repeat;
    height: 0.8rem !important;
    padding-right: 1.5rem;
    margin-left: 0;
    margin-top: 10px;
    left: 0;
    border-style: none !important;
}

.adr-card {
    .adr-card-body {
        padding-right: 50px;
        padding-left: 50px;
    }
}

.popover .popover-header {
    font-size: 13px;
    border: 1px solid #f0f3ff;
}

.popover-header {
    background-color: #f0f3ff;
    border-bottom: 0 solid #f0f3ff;
    color: #52657c !important;
    font-size: 13px !important;
}

.card.adr-card {
    border: solid 0.5px rgba(214, 217, 228, 0.7) !important;
}

.popover::before {
    content: " ";
    width: 20px;
    height: 20px;
    position: relative;
}

.popover-overlay {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: rgba(82, 101, 124, 0.4);
    top: 0px;
    right: 0;
    -webkit-transition: height 0.1s ease;
    z-index: 9999;
}

.popover {
    z-index: 99999;
}


.subMenuSelected {
    transform: rotate(45deg) !important;
}

.subMenuNotSelected {
    transform: rotate(225deg) !important;
}

footer {
    position: static;
    font-size: 10px;
    padding: 10px;
    color: #52657c !important;
}

.datatable-body-cell-label .adr-tag {
    padding: 0px 5px;
}

.breadcrumb-item {
    .btn.btn-link {
        position: relative;
        top: -1px;
    }
}

/* to hide pagination on tables */
.ngx-datatable.bootstrap .datatable-footer .datatable-pager {
    display: none;
}

.ngx-datatable.bootstrap.core-bootstrap .datatable-footer .page-count {
    color: #b9b9c3;
    text-align: center;
    padding-left: 200px;
    position: relative;
    top: 15px;
}

.breadcrumb-item a {
    color: #1877f2 !important;
}

.breadcrumb-item a:hover {
    font-weight: bold !important;
}

.sortable .datatable-header-cell-template-wrap .datatable-header-cell-wrapper {
    padding-left: 12px;
}

.ngx-datatable.bootstrap.core-bootstrap
.datatable-header
.datatable-header-cell {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

#Main-content {
    overflow: hidden;
    min-height: calc(100vh - 86px);
}

.float-right {
    float: right !important;
}

.minus-action {
    cursor: pointer;
}

.ngx-datatable.bootstrap.core-bootstrap .datatable-header {
    background: rgba(228, 233, 240, 0.7);
}
td {
    .input-group-number {
        margin-right: 0px;
        margin-left: auto;
    }
}

.IncludedServicesFees {
    tbody {
        tr {
            td:last-child {
                width: 130px;
            }
        }
    }
}

.btn-outline-info {
    padding: calc(0.486rem - 0.5px) calc(1rem - 0.5px) !important;
}

.adr-lh {
    line-height: 27.8px;

    .page-sub-title {
        line-height: 27.8px;
    }
}

.flex-fill.inbut-control.btn-control-icon {
    background-color: #efefef;
    max-width: 130px;
    width: 130px;
    height: 27.8px;
    line-height: 28px;
    padding: 0 10px;
    border-radius: 4.641px;
}

.form-control-select-adr {
    .ng-select-container {
        background: #f1f1f2;
        border-color: #f1f1f2;
        max-height: 27.8px !important;
        height: 27.8px !important;
        min-height: 27.8px !important;
    }
}

.card.card-adr {
    margin-bottom: 1rem;
}

.widget-card {
    .card-body {
        .widget-body.widget-primary {
            hr {
                border-width: 4px;
                border-radius: 5px;
                border-color: rgba(#1877f2, 0.5);
                margin-bottom: 5px;
            }
        }

        .widget-body.widget-warning {
            hr {
                border-width: 4px;
                border-radius: 5px;
                border-color: rgba(#f2c218, 0.5);
                margin-bottom: 5px;
            }
        }

        .widget-body.widget-succes {
            hr {
                border-width: 4px;
                border-radius: 5px;
                border-color: rgba(#00b929, 0.5);
                margin-bottom: 5px;
            }
        }

        .widget-body.widget-light {
            hr {
                border-width: 4px;
                border-radius: 5px;
                border-color: rgba(#aeaeae, 0.5);
                margin-bottom: 5px;
            }
        }

        .widget-body.widget-danger {
            hr {
                border-width: 4px;
                border-radius: 5px;
                border-color: rgba(#f61a07, 0.5);
                margin-bottom: 5px;
            }
        }

        .widget-body.widget-secondary {
            hr {
                border-width: 4px;
                border-radius: 5px;
                border-color: rgba(#152858, 0.5);
                margin-bottom: 5px;
            }
        }

        .widget-body {
            height: 100%;

            h4 {
                font-weight: 300;
                font-size: 0.875rem;
            }

            h3 {
                font-weight: 600;

                span.text-danger {
                    color: #ff3a29;
                }

                span.text-succes {
                    color: #34b53a;
                }

                span {
                    font-size: 0.875rem;
                }
            }
        }
    }
}

.card {
    .card-header {
        padding-bottom: 10px !important;
        h6.card-title {
            font-size: 0.875rem !important;
            color: #52657cb3 !important;
            font-weight: 300;
        }
    }
}

.details-Load-More-section {
    height: 0px;
    padding: 0px !important;
}

.Load-More-section {
    padding-top: 25px;
}

.table-badge {
    margin-right: 3px !important;
}

.timeline-stop-time {
    margin-right: 5rem;
    position: relative;
    bottom: 3px;
}

.Time-line-title {
    max-width: 85% !important;
}

.Stop-span {
    position: absolute;
    right: 0px;
    top: 18px;
    color: #fff !important;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    z-index: 999;
}

.text-primary {
    color: #152858 !important;
}

.bold {
    font-weight: bold;
}

.text-primary.invoice-logo {
    color: rgba(82, 101, 124, 0.7) !important;
    font-weight: 400;
    font-size: 15px !important;
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid #ebe9f1;
}

.info-container {
    margin-bottom: 10px;
    height: 30px;
    display: table-row;
}

.info-title {
    color: #1b3065;
    font-weight: 600;
    padding-right: 20px;
    border-bottom: 10px solid transparent;
}

.info-value {
    color: #1b3065;
    font-weight: 400;
    display: table-header-group;
}

.info-title,
.info-value {
    display: table-cell;
}

.w-30 {
    width: 30%;
}

.w-40 {
    width: 40%;
}

.w-70 {
    width: 70%;
}

.disc-list {
    list-style: disc;
}

.direction-ltr {
    direction: ltr;
}

.direction-rtl {
    direction: rtl;
}

.ride-sub-title {
    color: rgba(82, 101, 124, 0.7) !important;
    font-weight: 400;
    font-size: 13px !important;
}

.ride-Schedule-list {
    padding-left: 16px;
    margin-top: 5px;

    li {
        color: rgba(82, 101, 124, 0.7) !important;
        font-size: 10px;
    }
}

.k-hierarchy-cell {
    a {
        .k-icon-wrapper-host {
            .k-icon {
                font-size: inherit !important;
                text-rendering: auto !important;
                -webkit-font-smoothing: antialiased !important;
                -moz-osx-font-smoothing: grayscale !important;
                background: #ecf0f4;
                padding: 10px 10px;
                box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
                border-radius: 3px !important;
                color: #52657c !important;
            }
        }
    }
}

.k-grid .k-grid-header .k-table-th {
    position: relative;
    vertical-align: bottom;
    cursor: default;
    border-color: rgba(228, 233, 240, 0.7) !important;
    background: rgb(230 235 240);
}

.k-dialog-titlebar {
    border-color: inherit;
    color: #424242;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0.8rem 1.4rem;
    background-color: #f8f8f8;
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0.358rem !important;
    border-top-right-radius: 0.358rem !important;
}

.k-window-title.k-dialog-title {
    color: #52657c !important;
}

.k-dialog-wrapper .k-dialog {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(34, 41, 47, 0.2);
    border-radius: 0.358rem;
    outline: 0;
    box-shadow: 0 5px 20px 0 rgba(34, 41, 47, 0.1);
}


.k-datepicker {
    width: 100%;
    height: 2.714rem;
    padding: 0.438rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.45;
    color: #52657c;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.k-input-solid:focus,
.k-input-solid.k-focus {
    color: #52657c !important;
    background-color: #fff !important;
    border-color: #1877f2 !important;
    outline: 0;
    box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1) !important;
}

.k-button-flat-primary {
    color: #1877f2 !important;
}

.k-calendar .k-calendar-td.k-selected .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-selected .k-link {
    border-color: rgba(0, 0, 0, 0.08);
    color: white;
    background-color: #1877f2 !important;
}

.k-input-button {
    display: block !important;
}

.ng-select .ng-select-container {
    border: 1px solid #d8d6de !important;
    overflow: auto !important;
}

.cui-avatar-border-white {
    border-color: #fff;
}

.cui-avatar {
    width: 5.69rem;
    height: 5.69rem;
    background-color: #eef0f4;
    border-color: #b8beca;
    display: inline-block;
    overflow: hidden;
    border-radius: 500px;
}

.cui-avatar-90 {
    width: 6.92rem;
    height: 6.92rem;
}

.bg-pink {
    background-color: #f1f1f2 !important;
}

.app-profile-avatar {
    font-size: 25px;
    // background-color: #f1f1f2 !important;
    color: #152858 !important;
    border-radius: 50%;
    width: 75px;
    height: 37px;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    background-position: center center;
    background-size: cover;
    display: flex;
}

.cui-avatar-nav-bar {
    width: 2.69rem;
    height: 2.69rem;
    background-color: #eef0f4;
    border-color: #b8beca;
    display: inline-block;
    overflow: hidden;
    border-radius: 500px;
    color: #152858 !important;
}

.app-profile-avatar-nav-bar {
    font-size: 13px;
    background-color: #f1f1f2 !important;
    color: #152858 !important;
    border-radius: 50%;
    width: 36px;
    height: 0px;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    background-position: center center;
    background-size: cover;
    display: flex;
}

.avatar.profileImage [class*="avatar-status-"] {
    border-radius: 50%;
    width: 13px;
    height: 13px;
    position: absolute;
    right: 0;
    bottom: 7px;
    border: 1px solid #fff;
}

.form-table-control {
    padding-bottom: 5px;
}

.form-table-title {
    padding-right: 20px;
    white-space: nowrap;
    padding-bottom: 5px;
}

.input-group-adr .input-group-append .input-group-text {
    background-color: #f1f1f2;
    border-color: #f1f1f2;
    border-left-style: none !important;
}

.nav-pills .nav-link {
    // border-radius: 0.358rem;
    color: #152858;
    font-size: 14px;
}

.nav-pills .nav-link.active {
    color: #fff !important;
    background-color: #1877f2 !important;
    border-color: #1877f2;
    box-shadow: none !important;
}

@media (min-width: 576px) {
    .form-row .form-container:nth-child(odd) {
        padding-right: 13px !important;
    }

    .form-row .form-container:nth-child(even) {
        padding-left: 13px !important;
    }
}

@media (min-width: 768px) {
    .form-row .form-container:nth-child(odd) {
        padding-right: 13px !important;
    }

    .form-row .form-container:nth-child(even) {
        padding-left: 13px !important;
    }
}

@media (min-width: 992px) {
    .form-row .form-container:nth-child(odd) {
        padding-right: 25px !important;
    }

    .form-row .form-container:nth-child(even) {
        padding-left: 25px !important;
    }
}

.input-group-adr .input-group-append {
    background: #f1f1f2;
    padding: 0px 0px !important;
    color: rgba(21, 40, 88, 0.5);
    border-top-right-radius: 4.641px;
    border-bottom-right-radius: 4.641px;
}

.feather.font-small-4.icon-eye {
    background: #f1f1f2 !important;
}

.feather.font-small-4.icon-eye-off {
    background: #f1f1f2 !important;
}

#page-account-settings .row .tab-content {
    min-height: 100%;
}

#page-account-settings .row .tab-content .tab-pane {
    height: 100%;
}

#page-account-settings .row .tab-content .card {
    height: 100%;
}

.page-title h3 {
    font-weight: 600;
}

a.d-flex.align-items-center.dropdown-item.dropdown-toggle.ng-star-inserted {
    color: #fff;
}

li.dropdown.dropdown-submenu.ng-star-inserted:hover {
    a.d-flex.align-items-center.dropdown-item.dropdown-toggle.ng-star-inserted {
        background: #fff !important;
        color: #1877f2;
    }

    a::after {
        background-image: url(str-replace( str-replace($chevron-down, "currentColor", #1877f2), "#", "%23" ));
    }
}

.form-control-feild:disabled,
.form-control[readonly] {
    background-color: rgba(224, 229, 235, 0.5) !important;
    opacity: 1;
}

.noClick {
    pointer-events: none;
}

.col-form-label {
    font-weight: 500;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
}

.input-group-append:focus {
    background-color: #f4f6f8;
    border-color: #1877f2 !important;
}

.input-group-focus:focus {
    background-color: #f4f6f8;
    border: 1px solid #1877f2 !important;
}


.nav-tabs .nav-link:hover {
    border-color: #d6d9e4;
    border-bottom: none;
    color: #1877f2 !important;
    background-color: rgba(240, 243, 255, 0.5);
}

.nav-pills .nav-link:hover {
    border-radius: 0.358rem;
    color: #1877f2 !important;
    font-size: 14px;
}

.dropdown-item:hover,
.dropdown-item:focus {
    color: #1877f2 !important;
    text-decoration: none;
    background-color: rgba(228, 233, 240, 0.7);


}

.nav-pills .nav-link.active:hover {
    color: #fff !important;
}


.overlay {
    position: unset !important;
}

.form-control:focus + .input-group-append .input-group-text {
    border-left-style: none;
    border-color: #1877f2;
}


@media print {
    .no-print,
    .no-print * {
        display: none !important;
    }
}
.datatable-body-cell-label .btn {
    font-size: 13px !important;
}

.text-Paying-Client {
    width: 170px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.dllMultipleSelectore {
    .ng-select-container {
        height: 70px;
        align-items: baseline;
        padding-top: 3px;
    }

    .ng-value-container {
        height: auto;
    }
}

// }
.ng-select .ng-select-container .ng-value-container .ng-input > input {
    height: 24px !important;
}

.ng-select-multiple .ng-select-container .ng-value-container .ng-input > input {
    top: -2px;
    position: relative !important;
}


@media print {
    * {
        overflow: hidden !important;
    }
}

input[type="number"] {
    /*for absolutely positioning spinners*/
    position: relative;
    padding: 5px;
    padding-right: 25px;
    font-size: 13px !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    opacity: 1;
    -webkit-appearance: inner-spin-button !important;
    width: 25px;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
}

.ng-select .ng-arrow {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaBAMAAABbZFH9AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAbUExURQAAAJGRkZGRkZKSkpGRkZKSkpKSkpGRkZGRkcuyj70AAAAIdFJOUwD7UmhajS8sVAOaaQAAAAlwSFlzAAAOwwAADsMBx2+oZAAAAF5JREFUKM9joB1wQeYwCSog8RQ7hJCkJDoaFZCkOhCSLBIdgh2NDggpZ7gkUFczgwVMJ1DKgIEZKsku0SECpBw7GgtA3AigFANQshWskrUZTFkEQIxJAJNsDLQHDAwAzFgT7AcCvJgAAAAASUVORK5CYII=");
}

.ng-select.ng-select-multiple .ng-select-container .ng-placeholder {
    top: 9px !important;
}

.nav-tabs {
    border-bottom: solid 1px #d6d9e4;
    margin: 0 -20px;
    padding: 0 20px;
}

.nav-tabs .nav-link {
    border: solid 1px #d6d9e4;
    border-right: 0px;
    border-bottom: 0px;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    font-size: 14px;
    font-weight: 700;
    background: rgba(236, 240, 244, 0.5);
    color: #52657c !important;
}

.nav-tabs .nav-item:last-child .nav-link {
    border-right: solid 1px #d6d9e4;
}

.nav-tabs .nav-link.active:after {
    top: 0 !important;
    bottom: auto !important;
}

.nav-tabs .nav-link.active {
    border-bottom: solid 3px #ffff;
    margin-bottom: -2px;
}

.wrap-text {
    max-width: 100%;
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.app-type-head-c-w ngb-typeahead-window {
    max-height: 80px;
    overflow-y: scroll;
}


.dll-invalid-feedback {
    .invalid-feedback {
        margin-bottom: 0px !important;
        margin-top: 0px !important;
    }

    display: block !important;
    min-height: 14.5px;
    margin-top: 3px;
}

.ng-dropdown-panel.ng-select-bottom {
    border-bottom: 1px solid #1877f2 !important;
    border-left: solid 1px #1877f2 !important;
    border-right: solid 1px #1877f2 !important;

    .scroll-host {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}

.ng-dropdown-panel.ng-select-top {
    border-top: 1px solid #1877f2 !important;
    border-left: solid 1px #1877f2 !important;
    border-right: solid 1px #1877f2 !important;

    .scroll-host {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
}

a {
    cursor: pointer !important;
}

.modal-dialog-centered {
    min-height: calc(100% - 7.5rem) !important;
}
